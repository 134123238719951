<template>
	<!-- <div class="print">
        <div v-for="item in Array(1,2,3,4,5,6,7,8,9)" v-bind:key="item">
            <span v-for="(one,k) in Array(item)" v-text="one?'one':'青'" v-bind:key="k"></span>
        </div>
    </div>-->
	<div class="print">
		<div class="title_box">泉州公交排班计划表</div>
		<div class="center_box">
			<div class="center_left">
				线路：
				<span>K308路</span>
			</div>
			<div class="center_center">
				<div>
					上行：
					<span>中骏世界城->清濛缺井村</span>
				</div>
				<div class="down">
					下行：
					<span>清濛缺井村->中骏世界城</span>
				</div>
			</div>
			<div class="center_right">
				日期：
				<span>2020-02-21</span>
			</div>
		</div>
		<div class="bottom_table">
			<el-table :data="list" border style="width: 100%">
				<el-table-column type="index" width="60" label="次序" align="center"></el-table-column>
				<el-table-column prop="chehao" label="车号" min-width="10"></el-table-column>
				<el-table-column prop="chehao" label="方向" min-width="10"></el-table-column>
				<el-table-column prop="chehao" label="应开时间" min-width="10"></el-table-column>
				<el-table-column prop="chehao" label="实开时间" min-width="10"></el-table-column>
			</el-table>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			list: [
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 },
				{ chehao: 1 }
			]
		};
	},
	created() {
		this.$nextTick(() => {
			this.printFun();
		});
	},
	methods: {
		printFun() {
			window.print();
		}
	}
};
</script>
<style type="text/css" lang="scss" scoped="print"></style>
<style type="text/css" lang="scss">
.print {
	.title_box {
		text-align: center;
		font-size: 25px;
		font-weight: 500;
	}
	.center_box {
		margin-top: 10px;
		font-size: 13px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.center_left {
			margin-left: 20px;
		}
		.center_center {
			display: inline-flex;
			flex-direction: row;
			.down {
				margin-left: 15px;
			}
		}
		.center_right {
			margin-right: 20px;
		}
	}
	.bottom_table {
		width: 50%;
		padding: 15px 20px;
	}
}
</style>
